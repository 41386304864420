<template>
  <div class="p-t-5 p-l-5">
    <div v-if="moduleType === 'transaction' || moduleType === 'maintenance'">
      <Divider dashed style="margin: 10px 0 5px 0"><span class="divider-text">{{ totalSumaryBean.billTypeName
      }}汇总</span></Divider>
      <p>
        <span class="title">汇总时间：</span>
        {{ startDate ? startDate : "2020-01-01" }}
        <span class="title">到</span> {{ endDate ? endDate : "至今" }}
      </p>
      <Row class="text-center p-t-5 p-b-5" >

        <i-col span="24" class="text-16">
          合计结算额：<span class="text-orange">{{
          formatMoney(totalSumaryBean.amount)
          }}</span>
        </i-col>
        <i-col span="24" style="margin:10px;">
          <a style="font-size: xx-small;" @click="handleChangeCondition">
            <span v-if="!showMoreCondition">
              <Icon type="md-arrow-round-down" />点击显示更多明细
              <Icon type="md-arrow-round-down" />
            </span>
            <span v-else>
              <Icon type="md-arrow-round-up" />点击收起更多明细
              <Icon type="md-arrow-round-up" />
            </span>
          </a>
        </i-col>
        <i-col span="12" class="transaction-border text-center">
          <Row>
            <i-col span="24"  style="line-height:35px;" class="transaction-content">已结算信息</i-col>
            <i-col style="line-height:22px;" span="24">
              <label class="title">总额</label><label class="text-orange">{{ formatMoney(totalSumaryBean.commitedAmount) }}</label>
            </i-col>
            <template v-if="moduleType === 'transaction'&&showMoreCondition">
            <i-col  span="24" style="border-top: 1px solid #ccc;text-align:left;padding:5px;" class="transaction-content" >
              <p class="title">发布费</p>
              {{ formatMoney(totalSumaryBean.commitedSignUseAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">发布费调整项</p>
              {{ formatMoney(totalSumaryBean.committedSignUseFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(totalSumaryBean.commitedSignUseAmount+totalSumaryBean.committedSignUseFeeAdjustAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">服务费</p>
              {{ formatMoney(totalSumaryBean.commitedServiceAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">服务费调整项</p>
              {{ formatMoney(totalSumaryBean.committedServiceFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(totalSumaryBean.committedServiceFeeAdjustAmount+totalSumaryBean.commitedServiceAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content" v-if="totalSumaryBean.committedOtherFeeAdjustAmount&&totalSumaryBean.unCommittedOtherFeeAdjustAmount">
              <p class="title">其他调整项</p>
              {{ formatMoney(totalSumaryBean.committedOtherFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" v-if="totalSumaryBean.committedOtherFeeAdjustAmount&&totalSumaryBean.unCommittedOtherFeeAdjustAmount">
              <label class="title">小计</label>
              {{ formatMoney(totalSumaryBean.committedOtherFeeAdjustAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">罚款项</p>
              {{ formatMoney(totalSumaryBean.commitedPenaltyAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(totalSumaryBean.commitedPenaltyAmount) }}
            </i-col>

          </template>
          </Row>

        </i-col>
        <i-col span="12" class="transaction-border text-center">
          <Row>
            <i-col span="24"  style="line-height:35px;" class="transaction-content">待结算信息</i-col>
            <i-col style="line-height:22px;" span="24">
              <label class="title">总额</label><label class="text-orange">{{ formatMoney(totalSumaryBean.unCommitedAmount) }}</label>
            </i-col>
            <template v-if="moduleType === 'transaction'&&showMoreCondition">
              <i-col  span="24" style="border-top: 1px solid #ccc;text-align:left;padding:5px;" class="transaction-content">
              <p class="title">发布费</p>
              {{ formatMoney(totalSumaryBean.unCommitedSignUseAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">发布费调整项</p>
              {{ formatMoney(totalSumaryBean.unCommittedSignUseFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(totalSumaryBean.unCommitedSignUseAmount+totalSumaryBean.unCommittedSignUseFeeAdjustAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">服务费</p>
              {{ formatMoney(totalSumaryBean.unCommitedServiceAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">服务费调整项</p>
              {{ formatMoney(totalSumaryBean.unCommittedServiceFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(totalSumaryBean.unCommittedServiceFeeAdjustAmount+totalSumaryBean.unCommitedServiceAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content" v-if="totalSumaryBean.committedOtherFeeAdjustAmount&&totalSumaryBean.unCommittedOtherFeeAdjustAmount">
              <p class="title">其他调整项</p>
              {{ formatMoney(totalSumaryBean.unCommittedOtherFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" v-if="totalSumaryBean.committedOtherFeeAdjustAmount&&totalSumaryBean.unCommittedOtherFeeAdjustAmount">
              <label class="title">小计</label>
              {{ formatMoney(totalSumaryBean.unCommittedOtherFeeAdjustAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">罚款项</p>
              {{ formatMoney(totalSumaryBean.unCommitedPenaltyAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(totalSumaryBean.unCommitedPenaltyAmount) }}
            </i-col>

            </template>

          </Row>

        </i-col>

      </Row>

      <template v-if="
        totalSumaryBean.companyBillSummaryList &&
        totalSumaryBean.companyBillSummaryList.length > 0
      ">
        <div v-for="(company, i) in totalSumaryBean.companyBillSummaryList" :key="i">
          <h4 class="p-t-10 p-b-5">{{ company.companyName }}</h4>
          <Row style="border-top: 1px solid #ccc" v-if="moduleType === 'transaction'&&showMoreCondition">
            <i-col span="12" class="transaction-border text-center">
          <Row>
            <i-col span="24"  style="line-height:35px;" class="transaction-content">已结算信息</i-col>
            <i-col style="line-height:22px;" span="24">
              <label class="title">总额</label><label class="text-orange">{{ formatMoney(company.commitedAmount) }}</label>
            </i-col>
            <template v-if="moduleType === 'transaction'&&showMoreCondition">
            <i-col  span="24" style="border-top: 1px solid #ccc;text-align:left;padding:5px;" class="transaction-content">
              <p class="title">发布费</p>
              {{ formatMoney(company.commitedSignUseAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">发布费调整项</p>
              {{ formatMoney(company.committedSignUseFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(company.commitedSignUseAmount+company.committedSignUseFeeAdjustAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">服务费</p>
              {{ formatMoney(company.commitedServiceAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">服务费调整项</p>
              {{ formatMoney(company.committedServiceFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(company.committedServiceFeeAdjustAmount+company.commitedServiceAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content" v-if="company.committedOtherFeeAdjustAmount&&company.unCommittedOtherFeeAdjustAmount">
              <p class="title">其他调整项</p>
              {{ formatMoney(company.committedOtherFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" v-if="company.committedOtherFeeAdjustAmount&&company.unCommittedOtherFeeAdjustAmount">
              <label class="title">小计</label>
              {{ formatMoney(company.committedOtherFeeAdjustAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">罚款项</p>
              {{ formatMoney(company.commitedPenaltyAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(company.commitedPenaltyAmount) }}
            </i-col>

          </template>
          </Row>

        </i-col>
        <i-col span="12" class="transaction-border text-center">
          <Row>
            <i-col span="24"  style="line-height:35px;" class="transaction-content">待结算信息</i-col>
            <i-col style="line-height:22px;" span="24">
              <label class="title">总额</label><label class="text-orange">{{ formatMoney(company.unCommitedAmount) }}</label>
            </i-col>
            <template v-if="moduleType === 'transaction'&&showMoreCondition">
              <i-col  span="24" style="border-top: 1px solid #ccc;text-align:left;padding:5px;" class="transaction-content">
              <p class="title">发布费</p>
              {{ formatMoney(company.unCommitedSignUseAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">发布费调整项</p>
              {{ formatMoney(company.unCommittedSignUseFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(company.unCommitedSignUseAmount+company.unCommittedSignUseFeeAdjustAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">服务费</p>
              {{ formatMoney(company.unCommitedServiceAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">服务费调整项</p>
              {{ formatMoney(company.unCommittedServiceFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(company.unCommittedServiceFeeAdjustAmount+company.unCommitedServiceAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content" v-if="company.committedOtherFeeAdjustAmount&&company.unCommittedOtherFeeAdjustAmount">
              <p class="title">其他调整项</p>
              {{ formatMoney(company.unCommittedOtherFeeAdjustAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;" v-if="company.committedOtherFeeAdjustAmount&&company.unCommittedOtherFeeAdjustAmount">
              <label class="title">小计</label>
              {{ formatMoney(company.unCommittedOtherFeeAdjustAmount) }}
            </i-col>

            <i-col  span="24" style="text-align:left;padding:5px;" class="transaction-content">
              <p class="title">罚款项</p>
              {{ formatMoney(company.unCommitedPenaltyAmount) }}
            </i-col>
            <i-col  span="24" style="text-align:left;padding:5px;">
              <label class="title">小计</label>
              {{ formatMoney(company.unCommitedPenaltyAmount) }}
            </i-col>

            </template>

          </Row>

        </i-col>
          </Row>
          <Row style="border-top: 1px solid #ccc">
            <i-col span="12" class="title">合计结算额</i-col>
            <i-col span="12" class="text-orange">{{
            formatMoney(company.amount)
            }}</i-col>
          </Row>
        </div>
      </template>
      <p v-else class="text-center">暂无数据</p>
    </div>
    <div v-if="moduleType === 'performance'">
      <Divider dashed style="margin: 10px 0 5px 0"><span class="divider-text">收款汇总</span></Divider>
      <p>
        <span class="title">汇总时间：</span>
        {{ startDate ? startDate : "" }}
        <span v-if="startDate" class="title">到</span>
        {{ endDate ? endDate : "至今" }}
      </p>
      <Row class="text-center m-t-10 p-t-5 p-b-5 transaction-content">
        <i-col span="12">
          <p class="title">含税金额</p>
          {{ formatMoney(repaymentssummary.amount) }}
        </i-col>
        <i-col span="12">
          <p class="title">不含税金额</p>
          {{ formatMoney(repaymentssummary.amountWithoutTax) }}
        </i-col>
      </Row>

      <template v-if="
        repaymentssummary.companyRepaymentSummaryList &&
        repaymentssummary.companyRepaymentSummaryList.length > 0
      ">
        <div v-for="(company, i) in repaymentssummary.companyRepaymentSummaryList" :key="i">
          <h4 class="p-t-10 p-b-5">{{ company.companyName }}</h4>
          <Row>
            <i-col span="8" class="title">含税金额</i-col>
            <i-col span="16">{{ formatMoney(company.amount) }}</i-col>
          </Row>
          <Row>
            <i-col span="8" class="title">不含税金额</i-col>
            <i-col span="16">{{ formatMoney(company.amountWithoutTax) }}</i-col>
          </Row>
        </div>
      </template>
      <p v-else class="text-center">暂无数据</p>
    </div>
  </div>
</template>

<script>
import { getbillsSummary } from '@/api/statement/bill'
import { getbillrepaymentssummary } from '@/api/statement/billrepayment'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      showMoreCondition: false,
      startDate: '',
      endDate: '',
      totalSumaryBean: {},
      repaymentssummary: {}
    }
  },
  methods: {
    handleChangeCondition () {
      this.showMoreCondition = !this.showMoreCondition
    },
    initPageData (query) {
      this.startDate = query.startDate
      this.endDate = query.endDate

      getbillsSummary(query).then((res) => {
        this.totalSumaryBean = res
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  computed: {
    maintenanceSettlementQuery () {
      return this.$store.state.settlement.maintenanceSettlementQuery
    },
    transactionSettlementQuery () {
      return this.$store.state.settlement.transactionSettlementQuery
    },
    performanceQuery () {
      return this.$store.state.settlement.performanceQuery
    },
    moduleType () {
      return this.$store.state.settlement.moduleType
    },
    beginUpdate () {
      return this.$store.state.settlement.beginUpdate
    }
  },
  watch: {
    beginUpdate (val) {
      const query = {
        billType: 1,
        publisherId: this.$store.getters.token.userInfo.publisherId
      }

      if (this.moduleType === 'transaction') {
        query.billType = 1
        query.startDate = this.transactionSettlementQuery.startDate
        query.endDate = this.transactionSettlementQuery.endDate
        query.keyword = this.transactionSettlementQuery.keyword
        query.billStatus =
          this.transactionSettlementQuery.billStatus === -1
            ? null
            : this.transactionSettlementQuery.billStatus
        query.agentCompanyId = this.transactionSettlementQuery.agentCompanyId
        this.initPageData(query)
      } else if (this.moduleType === 'maintenance') {
        query.billType = 2
        query.startDate = this.maintenanceSettlementQuery.startDate
        query.endDate = this.maintenanceSettlementQuery.endDate
        query.keyword = this.maintenanceSettlementQuery.keyword
        query.billStatus =
          this.maintenanceSettlementQuery.billStatus === -1
            ? null
            : this.maintenanceSettlementQuery.billStatus
        query.purchaserCompanyId =
          this.maintenanceSettlementQuery.purchaserCompanyId
        query.supplierCompanyId =
          this.maintenanceSettlementQuery.supplierCompanyId
        this.initPageData(query)
      } else if (this.moduleType === 'performance') {
        // const summaryBean = {}
        const params = {}
        this.startDate = this.performanceQuery.startDate
        this.endDate = this.performanceQuery.endDate
        params.agentCompanyId = this.performanceQuery.agentCompanyId
        params.minDate = this.performanceQuery.startDate
        params.maxDate = this.performanceQuery.endDate
        params.keyword = this.performanceQuery.keyword
        params.invoiced =
          this.performanceQuery.invoiced === -1
            ? null
            : this.performanceQuery.invoiced === 1
        params.publisherId = this.$store.getters.token.userInfo.publisherId
        getbillrepaymentssummary(params).then((res) => {
          this.repaymentssummary = res
        })
      }
    }
  }
}
</script>
