import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取结算账单收款记录列表
 * @param {Object} data
 */
export function getbillrepaymentpage (data) {
  return request({
    url: '/ooh-statement/v1/billrepayment/getbillrepaymentpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 删除结算账单收款记录
 * @param {Object} data
 */
export function deletebillrepayment (data) {
  return request({
    url: '/ooh-statement/v1/billrepayment/deletebillrepayment',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 创建或者更新收款记录
 * @param {Object} data
 */
export function createorupdatebillrepayment (data) {
  return request({
    url: '/ooh-statement/v1/billrepayment/createorupdatebillrepayment',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取结算账单收款记录详情
 * @param {Object} data
 */
export function getbillrepayment (data) {
  return request({
    url: '/ooh-statement/v1/billrepayment/getbillrepayment',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取结算账单收款记录汇总
 * @param {Object} data
 */
export function getbillrepaymentssummary (data) {
  return request({
    url: '/ooh-statement/v1/billrepayment/getbillrepaymentssummary',
    method: 'post',
    data: qs.stringify(data)
  })
}
